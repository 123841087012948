import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import SalesAdoption from '../images/salesAdoption.png'
import SalesforceDashboard from '../images/salesforceDashboard.png'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';
import TeamDash from '../images/teamDash.svg';
import LiveWithCustomer from '../images/liveWithTheCustomer.svg';
import t1Planet from '../images/t1Planet.png';
import SalesforceGraph from '../images/salesforceGraph.png';
import ApiPuzzle from '../images/apiPuzzle.svg';

import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';
import UnifiedExperience from '../images/unifiedExperience.svg';


var lottie

export default class BetterTextMessaging extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Multimedia Messaging (MMS, Picture Messaging, Group Texting)</title>
        </Helmet>


        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Multimedia Messaging
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                Collaborate more closely with customers using picture messaging and group texting
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>


        <WrapperBackground color={colors.trulyDark} background="racecar" gradientType="purple">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
              Group Texting
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                When the job needs to get done urgently, it's critical to enable real-time collaboration.  With a 50% response rate, texting is where things get done.
                <br/><br/>
                Our group texting capability allows users to initiate and respond to group messages with multiple parties.
              </PageParagraph>
            </Box>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={ApiPuzzle} alt="Salesforce Dashboards" />
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={LiveWithCustomer} alt="Get Your Company Communicating" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              Picture Messaging
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                Collaborate in real-time with clients by integrating picture messaging into your existing SMS/text conversations.
                <br />
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>




        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="blueToGreen">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
              Integrated With Your CRM
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
              Sync all of your text activity back to your CRM without lifting a finger. Our system intelligently captures, formats, and syncs data to Salesforce while giving you full reporting capabilities.
              </PageParagraph>
            </Box>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={SalesforceGraph} alt="Salesforce Dashboards" />
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={UnifiedExperience} alt="Get Your Company Communicating" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                One Unified Experience Across All Devices
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
              Send and receive messages on the fly from any system (desktop, mobile, web, helpdesk, or even email)
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>


      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Bold = styled.div`
  font-weight: Bold;
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;